import React from "react";
import "./topnav.css";
import Dropdown from "../dropdown/Dropdown";
import ThemeMenu from "../thememenu/ThemeMenu";
import { useState, useEffect } from "react";
import MobileMenu from "../sidebar/MobileMenu";
import UserAvatar from "./userAvatar";
import { useRef } from "react";

const Topnav = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  let name =
    localStorage.getItem("firstName") + localStorage.getItem("lastName");
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const logout = () => {
    console.log("loggedout");
    localStorage.setItem("isauthenticated", false);
    localStorage.setItem("accessToken", null);
    localStorage.clear();
    props.setCurrentUser(null);
  };

  const handleOutsideClick = (event) => {
    if (event.target.className === "user-name") {
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick);
  }, []);
  return (
    <div className="topnav">
      <div className="topnav__search">
        {/* <input type="text" placeholder="Search here..." />
        <i className="bx bx-search"></i> */}
      </div>
      <div className="topnav__right">
        <MobileMenu />
        {console.log("loggedin", props?.curr_user?.isLoggedIn)}
        {props?.curr_user?.isLoggedIn == "true" ? (
          <div className="topnav__right-item" style={{ marginRight: "10px" }}>
            {/* dropdown here */}
            <UserAvatar userName={name} onClick={toggleDropdown} />
            {isDropdownOpen && (
              <div id="drop" ref={dropdownRef}>
                <Dropdown onLogout={logout} />
              </div>
            )}
          </div>
        ) : null}

        <div className="topnav__right-itemk">
          <ThemeMenu />
        </div>
      </div>
    </div>
  );
};

export default Topnav;
