// components/GoogleMap.js

import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};


function GoogleMapComponent({lat, lon}) {
    const center = {
        lat: parseFloat(lat),
        lng: parseFloat(lon),
    };
    return (
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
            <Marker position={center} />
            </GoogleMap>
        </LoadScript>
    );
}

export default GoogleMapComponent;
