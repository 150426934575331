import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import StatusCard from "../components/status-card/StatusCard";
import Table from "../components/table/Table";
import Badge from "../components/badge/Badge";
import statusCards from "../assets/JsonData/status-card-data.json";
import api from "../api/api";

const chartOptions = {
  series: [
    {
      name: "Secret",
      data: [40, 70, 20, 90, 36, 80, 30, 91, 60],
    },
   
  ],
  options: {
    color: ["#6ab04c", "#2980b9"],
    chart: {
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    legend: {
      position: "top",
    },
    grid: {
      show: false,
    },
  },
};

const NewParticipants = {
  head: [ "Name", "Date"],
 
};

const renderCusomerHead = (item, index) => <th key={index}>{item}</th>;

const renderCusomerBody = (item, index) => (
  <tr key={index}>
    <td>{item.name}</td>
    <td>{new Date(item.created_at).toDateString()}</td>
  </tr>
);

const latestOrders = {
  header: ["Title", "date"],

};

const meetingStatus = {
  CONFIDENTIAL: "primary",
  SECRET: "warning",
  SENSITIVE: "danger",
};

const renderOrderHead = (item, index) => <th key={index}>{item}</th>;

const renderOrderBody = (item, index) => (
  <tr key={index}>
    <td>{item.title}</td>
    <td>{new Date(item.created_at).toDateString()}</td>
  </tr>
);

const Dashboard = () => {
  const [statsData,setStatsData]=useState([])
  const [blogs,setBlogs]=useState([])
  const [users,setUsers]=useState([])
  const [categories,setCategories]=useState([])
  const [userChartData,setUsersChart]=useState([])
  const themeReducer = useSelector((state) => state.ThemeReducer.mode);


  const getStatsData=async ()=>{
    const resp = await api.get(`admin/dashboard/stats`);
    if(resp.data.status){
      setStatsData(resp.data.data);
    }

    const respBlogs = await api.get(`admin/dashboard/blogs`);
    if(respBlogs.data.status){
      setBlogs(respBlogs.data.blogs);
    }

    
    const respUsers = await api.get(`admin/dashboard/users`);
    if(respUsers.data.status){
      setUsers(respUsers.data.users);
    }
  }
const getSignUpChart=async ()=>{
  let series=[];
  const resp = await api.get(`admin/dashboard/trend`);
  if(resp.data.status){
    let countData=[];
    let bookingData=[];
    let categoriesData=[]
    resp.data.users.map(item=>{
      countData.push(item.count);
      categoriesData.push(item.monthYear)
    })
    resp.data.bookings.map(item=>{
      bookingData.push(item.count);
    })
    series.push({name:"Users",data:countData})
    series.push({name:"Bookings",data:bookingData})
    console.log("series",series)
    setUsersChart(
      series
      );

      console.log("categoriesData",categoriesData);
      chartOptions["options"]["xaxis"]["categories"]=categoriesData

      setCategories(categoriesData)
  }
}
  useEffect(()=>{
    getStatsData()
    getSignUpChart()
  },[])
  return (
    <div>
      <h2 className="page-header">Dashboard</h2>
      <div className="row">
        <div className="col-6 col-sm-12">
          <div className="row">
            {statsData?.map((item, index) => (
              <div className="col-6 col-sm-12" key={index}>
                <StatusCard
                  icon={item.icon}
                  count={item.count}
                  title={item.title}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="col-6 col-sm-12">
          <div className="card full-height">
            {/* chart */}
            {userChartData&&userChartData.length&&<Chart
              options={
                themeReducer === "theme-mode-dark"
                  ? {
                      ...chartOptions.options,
                      theme: { mode: "dark" },
                    }
                  : {
                      ...chartOptions.options,
                      theme: { mode: "light" },
                    }
              }
              series={userChartData}
              type="line"
              height="100%"
            />}
          </div>
        </div>
        <div className="col-4 col-sm-12">
          <div className="card">
            <div className="card__header">
              <h3>New Users</h3>
            </div>
            <div className="card__body">
             {users&&users.length&& <Table
                headData={NewParticipants.head}
                renderHead={(item, index) => renderCusomerHead(item, index)}
                bodyData={users}
                renderBody={(item, index) => renderCusomerBody(item, index)}
              />}
            </div>
            <div className="card__footer">
              <Link to="/">view all</Link>
            </div>
          </div>
        </div>
        <div className="col-8 col-sm-12">
          <div className="card">
            <div className="card__header">
              <h3>latest Blogs</h3>
            </div>
            <div className="card__body">
             {blogs&&blogs.length&& <Table
                headData={latestOrders.header}
                renderHead={(item, index) => renderOrderHead(item, index)}
                bodyData={blogs}
                renderBody={(item, index) => renderOrderBody(item, index)}
              />}
            </div>
            <div className="card__footer">
              <Link to="/">view all</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
