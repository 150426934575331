import React from "react";
import FreeListingForm from "./FreeListingForm";

function AddFreeListing() {
  return (
    <div>
      <FreeListingForm />
      {/* <div>
        <h1>Location Search</h1>
        <LocationSearch />
      </div> */}
    </div>
  );
}

export default AddFreeListing;
