import React from "react";
import ListingFormMerged from "./ListingFormMerged";

function AddListingMerged(props) {
  return (
    <div>
      <ListingFormMerged {...props}/>
      {/* <div>
        <h1>Location Search</h1>
        <LocationSearch />
      </div> */}
    </div>
  );
}

export default AddListingMerged;
