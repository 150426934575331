import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import toastr from "toastr";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import api from "../../api/api";
const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [refetch, setrefetch] = useState(false);

  const handleDelete = async (id) => {
    // Replace this with your API endpoint and logic for deleting data

    try {
      const response = await api.delete(`admin/users/${id}`);

      console.log(response);
      toastr.success(response.data.message);
      setrefetch(!refetch);
    } catch (error) {
      // Handle error

      toastr.error("Error while deleting user");
    }
  };

  const fetchData = async () => {
    try {
      const response = await api.get(`admin/users`);

      // Process the response data
      const data = response.data;
      setCustomers(data);
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  };
  const copyToClipBoard = (data, message) => {
    navigator.clipboard.writeText(data);
    toastr.success(`${message} copied to clipboard`);
  };

  useEffect(() => {
    fetchData();
  }, [refetch]);

  return (
    <div className="flex">
      <div className="w-1/2"></div>
      <div className="w-1/2">
        <h2>Customers</h2>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer, Index) => (
                <TableRow key={customer.id}>
                  <TableCell>{Index + 1}</TableCell>
                  <TableCell>{customer.email.split("@")[0]}</TableCell>
                  <TableCell>{customer.name}</TableCell>{" "}
                  <TableCell
                    onClick={() => copyToClipBoard(customer.email, "Email")}
                  >
                    {customer.email}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleDelete(customer?.id)}
                      //   onClick={() => handleDelete(feature.id, "feature")}
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Customers;
