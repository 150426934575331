import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Modal,
  Card,
  CardContent,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  TableRow,
  Grid,
  Paper,
} from "@material-ui/core";

import api from "../../api/api";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    backgroundColor: "white",
    width: "50%",
    padding: theme.spacing(2),
    outline: "none",
  },
}));

let edit_id = "";
let isEdit = false;

const AddCategoryFormModal = ({ isOpen, newCategories, onClose }) => {
  const classes = useStyles();
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [filename, setFileName] = useState(null);
  const [categories, setCategories] = useState([]);

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const fetchData = async () => {
    try {
      const response = await api.get(`admin/category`);

      // Process the response data
      const data = response.data;
      setCategories(data);
      newCategories(data);
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setUploadImage(file)
    setFileName(file.name)
    setImage( URL.createObjectURL(file) );
  };
const updateThumb=async (id)=>{
  if(uploadImage){
    
    const formmData = new FormData();
    formmData.append("image", uploadImage);
    const resp = await api.put(`admin/category/image/${id}`, formmData);
    console.log("image response",resp);
    setImage(null);
    setUploadImage(null);
    setFileName('');
    fetchData();

  }

}
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (edit_id) {
      await api
        .put(`admin/category/${edit_id}`, { name: category })
        .then((res) => {
          if (res.data.success) {
            updateThumb(edit_id)
            toastr.success(res.data.message);
            edit_id = "";
            isEdit = false;
            setCategory("");
            // setBlogData(initialBlogData);
          } else {
            toastr.error(res.data.message);
          }
        });
    } else {
      await api.post(`admin/category`, { name: category }).then((res) => {
        if (res.data.success) {
          updateThumb(res.data.id)
          toastr.success(res.data.message);
          setCategory("");
          isEdit = false;
          // setBlogData(initialBlogData);
        } else {
          toastr.error(res.data.message);
        }
      });
    }
  };

  const handleEdit = (id, name,image) => {
    isEdit = true;
    edit_id = id;
    setCategory(name);
    setImage(image)
    console.log("Edit feature at index:", id);
  };

  const handleDelete = async (id) => {
    // Remove the feature from the list
    await api.delete(`admin/category/${id}`).then((res) => {
      if (res.data.success) {
        toastr.success(res.data.message);
        fetchData();
      } else {
        toastr.error(res.data.message);
      }
    });
  };

  return (
    <Modal open={isOpen} onClose={onClose} className={classes.modal}>
      <Card className={classes.card}>
        <CardContent>
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              marginBottom: "20px",
              flexDirection: "row",
            }}
          >
         
            
            <Grid item xs={12}>
            <TextField
            label="Category"
            value={category}
            onChange={handleCategoryChange}
            variant="outlined"
            fullWidth
          />
          <Grid item xs={12}>

            {image!= null?<img src={image}
style={
  {
    maxHeight:"300px"
  }
}            />:""}
          </Grid>

            <input
            type="file"
            filename={filename}

            accept="image/*"
            onChange={handleImageUpload}
            style={{ marginBottom: "1rem" }}
          />
          </Grid>

            <Button
              type="submit"
              style={{ width: "200px", marginLeft: "20px" ,height:"55px"}}
              variant="contained"
              color="primary"
            >
              {isEdit ? "Edit Category" : "Add Category"}
            </Button>

          </form>
          <Typography variant="h4" align="center" gutterBottom>
            Add Category
          </Typography>
          <CategoryDataGrid
            categories={categories}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </CardContent>
      </Card>
    </Modal>
  );
};

const CategoryDataGrid = ({ categories, onEdit, onDelete }) => {
  return (
    <TableContainer component={Paper} style={{ maxHeight: "300px" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>Edit</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map((category, index) => (
            <TableRow key={index}>
              <TableCell>{category.name}</TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onEdit(category.id, category.name,category.image)}
                >
                 {isEdit? "Edit":"Add"}
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => onDelete(category.id)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AddCategoryFormModal;
