import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import toastr from "toastr";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import api from "../../api/api";

const Reviews = () => {
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    getReviews()
  }, [])
  const getReviews = (row, type) => {
    // Replace this with your API endpoint and logic for updating data
    const endpoint = "/admin/reviews";

    api
      .get(endpoint, row)
      .then((response) => {
        // Handle success response
        setFeatures(response.data.data);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };


  const handleApprove = (id) => {
    // Replace this with your API endpoint and logic for updating data
    const endpoint = `/admin/review/approve/${id}`;

    api
      .post(endpoint)
      .then((response) => {
        // Handle success response
        console.log(response.data);
        if (response.data.status) {
          toastr.success("Review approved successfully");
          getReviews()

        } else {
          toastr.error("Something went wrong");

        }

      })
      .catch((error) => {
        // Handle error
        console.error(error);

        toastr.error("Something went wrong");
      });
  };

  const handleDelete = (id) => {
    // Replace this with your API endpoint and logic for deleting data
    const endpoint =
      `/admin/review/${id}`;

    api
      .delete(endpoint)
      .then((response) => {
        // Handle success response
        if (response.data.status) {
          toastr.success("Review deleted successfully");
          getReviews()

        } else {

          toastr.error("Something went wrong");
        }

        console.log(response.data);

      })
      .catch((error) => {

        toastr.error("Something went wrong");
        // Handle error
        console.error(error);
      });
  };

  return (
    <div className="flex">
      <div className="w-1/2"></div>
      <div className="w-1/2">
        <h2>Reviews</h2>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Booked Property</TableCell>
                <TableCell>Ratings</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {features.map((feature) => (
                <TableRow key={feature.id}>
                  <TableCell>{feature.id}</TableCell>
                  <TableCell>{feature.message}</TableCell>
                  <TableCell>{feature.listing.title}</TableCell>
                  <TableCell>
                    {
                      <Rating
                        className="mt-2"
                        name="size-medium"
                        readOnly
                        precision={0.25}
                        defaultValue={feature.rating}
                      />
                    }
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "300px",
                    }}
                  >
                    {feature.isApproved==false && <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => handleApprove(feature.id)}
                    >
                      Approve
                    </Button>}
                    <Button
                      onClick={() => handleDelete(feature.id)}
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{ paddingTop: "20px" }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Reviews;
