import React, { useRef, useState, useEffect } from "react";

import logo from "../../assets/images/logo.png";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import sidebar_items from "../../assets/JsonData/sidebar_routes.json";
import "./mobilemenu.css";

const clickOutsideRef = (content_ref, toggle_ref) => {
  document.addEventListener("mousedown", (e) => {
    // user click toggle
    if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
      content_ref.current.classList.toggle("active");
    } else {
      // user click outside toggle and content
      if (content_ref.current && !content_ref.current.contains(e.target)) {
        content_ref.current.classList.remove("active");
      }
    }
  });
};

const MobileMenu = (props) => {
  const menu_ref = useRef(null);
  const menu_toggle_ref = useRef(null);
  const setActiveMenu = () => menu_ref.current.classList.add("active");
  const closeMenu = () => menu_ref.current.classList.remove("active");

  clickOutsideRef(menu_ref, menu_toggle_ref);

  const SidebarItem = (props) => {
    const active = props.active ? "active" : "";

    return (
      <div className="sidebar__item ">
        <div className={`sidebar__item-inner ${active}`}>
          <i className={props.icon}></i>
          <span>{props.title}</span>
        </div>
      </div>
    );
  };
  const location = useLocation();
  const activeItem = sidebar_items.findIndex(
    (item) => item.route === location.pathname
  );

  useEffect(() => {}, []);
  return (
    <div className="mobilenav">
      <button
        ref={menu_toggle_ref}
        className="dropdown__toggle"
        style={{ marginRight: "20px" }}
        onClick={() => setActiveMenu()}
      >
        MENU
      </button>
      <div ref={menu_ref} className="theme-menu">
        <div className="sidebar__logo">
          <img src={logo} width="200"  alt="Algarve" />{" "}
          <button className="theme-menu__close" onClick={() => closeMenu()}>
            <i className="bx bx-x"></i>
          </button>
        </div>

        <ul className="mode-list">
          <div className="sidebarm ">
            {console.log(sidebar_items)}
            {sidebar_items.map((item, index) => (
              <Link to={item.route} key={index}>
                <SidebarItem
                  title={item.display_name}
                  icon={item.icon}
                  active={index === activeItem}
                />
              </Link>
            ))}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default MobileMenu;
