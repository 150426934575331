// import React from "react";
// import AddBlogForm from "./AddBlogForm";

// function AddBlog() {
//   return (
//     <div>
//       <AddBlogForm />
//     </div>
//   );
// }

// export default AddBlog;

import React from "react";
import AddBlogForm from "./AddBlogForm";

const AddBlog = () => {
  return (
    <div>
      <AddBlogForm />
    </div>
  );
};

export default AddBlog;
