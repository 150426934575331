import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  FormHelperText,
} from "@material-ui/core";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  Container,
  Grid,
  makeStyles,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PhotoCamera } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import api from "../../api/api";
import AddFeatureForm from "./AddFeatureFormModal";
import AddCategoryFormModal from "./AddCategoryFormModal";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ReactQuill from "react-quill";
// import MapWithPinPoint from "../../components/MapWithpinPoint";
/**
 * Google maps added by nabeel
 */
import GoogleMapComponent from "../../components/GoogleMaps";

const initialFormState = {
  title: "",
  location: "",
  category_id: "",
  description: "",
  // no_of_guests: "",
  // no_of_adults: "",
  // no_of_pets: "",
  city: "",
  country: "Portugal",
  stay_type: "",
  video_link: "",
  features: [],
  rent: 0,
  contact_number: "",
  thumb:[],thumb_upload:[],
  gallery: [],
  upload_gallery:[],
  duration:"",
  facebook:"",
  instagram:"",
  whatsapp:"",
  lat: "",
  lon: "",
  short_description: "",
  long_description: "", // From pluralo api pluralo -> agent's products.
  additional_info: "", //From pluralo api pluralo -> agent's products.
  image_logo: "", //From pluralo api pluralo -> agent's products.
  agent_id: "", //From pluralo api pluralo -> selected agent.
  product_id: "", //From pluralo api pluralo -> agent's products selected product.
};
const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  card: {
    width: "100%",
    padding: "20px",
    backgroundColor: "white",
  },
  field: {
    marginBottom: "10px",
  },
  submitButton: {
    marginTop: "20px",
  },
  ButtonsBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container: {
    backgroundColor: "white",
    width: "100%",
    padding: "10px",
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

let isEditing = false;

const ListingForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState(initialFormState);
  const [listings, setListings] = useState([]);
  const [agentList, setagentsList] = useState([]);
  const [selectedAgent, setSelectAgent] = useState(null);
  const [selectedEditAgent, setSelectEditAgent] = useState(null);
  const [agentProductsList, setAgentProductsList] = useState([]);
  const [selectedProduct, setSelectProduct] = useState(null);
  // const [F, setF] = useState([]);

  const [locations, setLocations] = useState([]);
  const [Features, setFeatures] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCatModalOpen, setIsCatModalOpen] = useState(false);
  const [errorStatus, setErrorStatus] = useState({
    agent_id: true,
    product_id: true,
    title: true,
  });
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpn = () => {
    setOpen(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    console.log("name");
    if (formValues.title !== "") {
      setErrorStatus({ ...errorStatus, title: false });
      console.log(errorStatus);
    }
  };
  const handleInputAdditionalInfo = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      ["additional_info"]: e,
    }));
  };

  const handleInputChangedescription = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      ["long_description"]: e,
    }));
  };

  const fetchData = async () => {
    try {
      const response = await api.get(`admin/listings`);

      // Process the response data
      const data = response.data.filter((item) => item.is_free === 0);
      setListings(data);
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  };
  const fetchAgents = async () => {
    try {
      const response = await api.get(`suppliers`);

      // Process the response data
      const data = response.data;
      setagentsList(data?.Data);
      return data;
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  };

  const fetchFeatures = async () => {
    try {
      // const response = await api.get(`admin/features`);
      // Process the response data
      // const data = response.data;
      // setF(data);
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  };
  const fetchAgentsProducts = async (agentId) => {
    try {
      const response = await api.get(`suppliers/${agentId}`);

      // Process the response data
      const data = response.data;
      setAgentProductsList(data?.Data);

      return data;
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  };
  const fetchLocations = async () => {
    try {
      const response = await api.get("admin/city");
      setLocations(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const settingLocation = (data) => {
    console.log("form values", formValues);
    const coordinates = data?.center;
    const address = data?.name?.split(",");
    setFormValues({
      ...formValues,
      lat: coordinates?.lat,
      lon: coordinates?.lng,

      location: data?.name,
      //  country: address?.pop(),
      // city: address?.toString(),
    });
  };

  const [loc, setloc] = useState("");
  const settingLocationByDropDown = (e) => {
    setloc(e.target?.value);

    if (e.target?.value) {
      let selectedLocation = locations?.find((o) => o.city === e.target?.value);

      console.log("selected", selectedLocation);
      setFormValues((prev) => ({
        ...prev,
        lat: selectedLocation.lat,
        lon: selectedLocation.lon,
        city: selectedLocation.city,
        country: selectedLocation.country,
        location: selectedLocation.city + " , " + selectedLocation.country,
        location_id: selectedLocation?.id,
      }));
    }
  };

  useEffect(() => {
    fetchData();
    fetchAgents();
    fetchFeatures();
    fetchLocations();
  }, []);

  // useEffect(() => {
  //   if (selectedAgent != null) {
  //     fetchAgentsProducts(selectedAgent);
  //   }
  // }, [selectedAgent]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const updatedFeatures = checked
      ? [...formValues.features, name]
      : formValues?.features?.filter((feature) => feature !== `${name}`);
    setFormValues({ ...formValues, features: updatedFeatures });
  };
  const handleThumbChange = (e) => {
    isEditing = false;
    const files = e.target.files;
    let newfiles = [];
    for (let i = 0; i < files.length; i++) {
      newfiles?.push(files[i]);
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      thumb:newfiles,

      thumb_upload:newfiles
    }));
  };
  const handleImageChange = (e) => {
    isEditing = false;
    const files = e.target.files;
    let newfiles = [];
    for (let i = 0; i < files.length; i++) {
      newfiles?.push(files[i]);
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      gallery: newfiles,
      upload_gallery: newfiles,

    }));
  };

  const endpoint = `/admin/listings`;

  const handleSubmit = async (e) => {
    if (formValues.gallery.length == 0) {
      toastr.error("Please Upload Some Gallery Image");
    } else {
      e.preventDefault();

      console.log(errorStatus);

      try {
        handleOpn();
        if (formValues.id) {
          await api
            .put(`admin/listings/${formValues.id}`, formValues)
            .then((res) => {
              if (res.data.success) {
                console.log("this is message", res.data.message);
                Addimage(formValues.id);
                AddThumb(formValues.id);
                toastr.success(res.data.message);

                // setBlogData(initialBlogData);
              } else {
                toastr.error(res.data.msg);
                handleClose();
              }
            });
          // const updatedBlogs = blogs.filter((blog) => blog.id !== blogId);
          // setBlogs(updatedBlogs);
        } else {
          const response = await api.post(endpoint, formValues);

          if (response.data.success) {
            console.log("listingadded", response?.data);
            toastr.success("New listing added");
            Addimage(response.data.data.id);
            AddThumb(response.data.data.id);
          } else {
            toastr.error(response.data.message);
          }
        }
      } catch (error) {
        handleClose();
        console.log(error);
        toastr.error("Error while creating listings");
      }
    }
  };
  //   function getBase64(file) {
  //    var reader = new FileReader();
  //    reader.readAsDataURL(file);
  //    reader.onload = function () {
  //      console.log(reader.result);
  //    };
  //    reader.onerror = function (error) {
  //      console.log('Error: ', error);
  //    };
  // }

  const AddThumb = (id) => {
    if(formValues?.thumb_upload?.length){

      
    const formData = new FormData();
    for (let i = 0; i < formValues?.thumb_upload?.length; i++) {
      formData.append("gallery", formValues?.thumb_upload[i]);
    }
    // formValues.gallery.forEach((item) => formData.append(item));
    // formData.append("gallery", formValues.gallery);

    try {
      api.put(`admin/listings/thumb/${id}`, formData).then((resp) => {
    
      });
    } catch (error) {
      toastr.error(error);

      handleClose();
    }
  }else{
    handleClose();
    setFormValues(initialFormState);
    setActiveStep(0)

  }
  };
  const Addimage = (id) => {
    if(formValues?.upload_gallery?.length){

      
    const formData = new FormData();
    for (let i = 0; i < formValues?.upload_gallery?.length; i++) {
      formData.append("gallery", formValues?.upload_gallery[i]);
    }
    // formValues.gallery.forEach((item) => formData.append(item));
    // formData.append("gallery", formValues.gallery);

    try {
      api.put(`admin/listings/gallery/${id}`, formData).then((resp) => {
        console.log("this is res after image uploading", resp);
        setFormValues(initialFormState);
    setActiveStep(0)
    fetchData();
        handleClose();
      });
    } catch (error) {
      toastr.error(error);

      handleClose();
    }
  }else{
    handleClose();
    setFormValues(initialFormState);
    setActiveStep(0)

  }
  };


  // const Addimage = (id) => {
  //   const formData = new FormData();
  //   for (let i = 0; i < formValues?.gallery?.length; i++) {
  //     formData.append("gallery", formValues?.gallery[i]);
  //   }
  //   // formValues.gallery.forEach((item) => formData.append(item));
  //   // formData.append("gallery", formValues.gallery);

  //   try {
  //     api.put(`admin/listings/gallery/${id}`, formData).then((resp) => {
  //       console.log("this is res after image uploading", resp);
  //       setFormValues(initialFormState);
  //       fetchData();
  //       handleClose();
  //     });
  //   } catch (error) {
  //     toastr.error(error);

  //     handleClose();
  //   }
  // };

  const handleCatButtonClick = () => {
    setIsCatModalOpen(true);
  };

  const handleCatCloseModal = () => {
    setIsCatModalOpen(false);
  };

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEdit = async (listing) => {
    isEditing = true;
    // Populate the form with the selected listing data
    //  setSelectProduct({})
    // console.log("listing editing", listing);
    // const agentid = agentList.find((a) => a.Id === listing.agent_id);
    setSelectAgent(listing.agent_id);

    console.log("selected agent", selectedAgent);
    const pd = await fetchAgentsProducts(listing.agent_id);
    const selectedpd = pd?.Data?.find((a) => a.Id === listing.product_id);

    setSelectProduct(selectedpd);

    console.log("ima listing", listing);
    setFormValues({ ...listing, stay_type: listing.stay_type });

    setActiveStep(0); // Move to the 1st step
  };

  const handleDelete = async (id) => {
    const response = await api.delete(`admin/listings/${id}`);
    // const updatedBlogs = blogs.filter((blog) => blog.id !== blogId);
    // setBlogs(updatedBlogs);
    if (response.data.success) {
      toastr.success(response.data.message);
      fetchData();
    } else {
      toastr.error(response.data.message);
    }
  };

  const handleNext = () => {
    if (
      formValues.title !== "" &&
      formValues.agent_id !== "" &&
      formValues.product_id !== ""
    ) {
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      toastr.error("please input mandatory fields");
    }
  };
  const handleRemoveImage = async (image) => {
    if (isEditing) {
      const response = await api.delete(`admin/listings/gallery/${image.id}`);
      if (response.data.success) {
        const updatedGallery = formValues.gallery.filter(
          (item) => item.id !== image.id
        );
        setFormValues({ ...formValues, gallery: updatedGallery });
        toastr.success(response.data.message);
      }
    } else {
      const updatedGallery = formValues.gallery.filter(
        (item) => item.id !== image.id
      );
      setFormValues({ ...formValues, gallery: updatedGallery });
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  // const Map = useCallback(
  //   () => (
  //     console.log("m RR HOA"),
  //     (

  //     )
  //   ),
  //   [formValues?.lat, formValues?.lon]
  // );

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel shrink={isEditing || formValues?.agent_id !== ""}>
                  Agents*
                </InputLabel>
                {console.log(formValues, "dd")}
                <Select
                  name="agent_id"
                  error={formValues.agent_id === ""}
                  required
                  value={isEditing ? formValues.agent_id : selectedAgent}
                  onChange={(e) => {
                    setSelectAgent(e.target.value);
                    fetchAgentsProducts(e.target.value);
                    handleFormChange(e);

                    setErrorStatus({ ...errorStatus, agent_id: false });

                    console.log("eroor ", errorStatus);
                  }}
                  label="Agent"
                >
                  <MenuItem value="">Select Agent</MenuItem>

                  {agentList?.map((agent) => (
                    <MenuItem key={agent.Id} value={agent?.Id}>
                      {agent.BrandName}
                    </MenuItem>
                  ))}
                </Select>
                {errorStatus?.agent_id ? (
                  <FormHelperText>This field is required</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel shrink={isEditing || formValues?.product_id !== ""}>
                  Product*
                </InputLabel>
                <Select
                  name="Product"
                  error={formValues.product_id === ""}
                  label="Product"
                  required
                  value={isEditing ? selectedProduct : formValues.product_id}
                  onChange={(e) => {
                    setSelectProduct(e.target.value);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      title: e.target?.value?.Name,
                      long_description: e.target?.value?.Description,
                      short_description: e.target?.value?.ShortDescription,
                      additional_info: e.target?.value?.AdditionalInformation,
                      image_logo: e.target.value?.ImageLogo,
                      product_id: e.target.value?.Id,
                      agent_id: selectedAgent,
                    }));

                    setErrorStatus({ ...errorStatus, product_id: false });

                    console.log("eroor ", errorStatus);
                  }}
                >
                  <MenuItem value="">Select Product</MenuItem>

                  {agentProductsList?.map((item) => (
                    <MenuItem key={item.Id} value={item}>
                      {item.Name}
                    </MenuItem>
                  ))}
                </Select>
                {errorStatus?.product_id ? (
                  <FormHelperText>This field is required</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel shrink={isEditing || formValues.title !== ""}>
                  Title*
                </InputLabel>
                <TextField
                  fullWidth
                  error={formValues?.title === ""}
                  name="title"
                  required
                  variant="outlined"
                  value={formValues?.title}
                  onChange={handleFormChange}
                />
              </FormControl>
              {formValues?.title === "" ? (
                <FormHelperText>This field is required</FormHelperText>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Category</InputLabel>
                <Select
                  name="category_id"
                  value={formValues?.category_id}
                  onChange={handleFormChange}
                  label="Category"
                >
                  <MenuItem value="">Select Category</MenuItem>

                  {Categories?.map((category) => (
                    <MenuItem key={category} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Stay Type</InputLabel>
                <Select
                  name="stay_type"
                  value={formValues?.stay_type}
                  onChange={handleFormChange}
                  label="Category"
                >
                  <MenuItem value="">Stay Type</MenuItem>
                  <MenuItem key={"4"} value={"Day Stay"}>
                    Day Stay
                  </MenuItem>
                  <MenuItem key={"1"} value={"Night Stay"}>
                    Night Stay
                  </MenuItem>
                  <MenuItem key={"2"} value={"Weekly Stay"}>
                    Weekly Stay
                  </MenuItem>{" "}
                  <MenuItem key={"3"} value={"Monthly Stay"}>
                    Monthly Stay
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                border: "1px solid black",
                borderWidth: "1px",
                backgroundColor: "pink",
              }}
            >
              Description
              <ReactQuill
                name="description"
                label="Product Description"
                value={formValues.long_description}
                onChange={handleInputChangedescription}
                style={{ marginBottom: "1rem" }}
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
              fullWidth
              name="duration"
              label="Duration"
              type="number"
              variant="outlined"
              value={formValues?.duration}
              onChange={handleFormChange}
            />
          </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="no_of_guests"
                label="No of Guests"
                type="number"
                variant="outlined"
                value={formValues?.no_of_guests}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="no_of_adults"
                label="No of Adults"
                type="number"
                variant="outlined"
                value={formValues?.no_of_adults}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="no_of_pets"
                label="No of Pets"
                type="number"
                variant="outlined"
                value={formValues?.no_of_pets}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                border: "1px solid black",
                borderWidth: "1px",
                backgroundColor: "pink",
              }}
            >
              Additional Information
              <ReactQuill
                name="additional_info"
                label="Additional Information"
                value={formValues.additional_info}
                onChange={handleInputAdditionalInfo}
                style={{ marginBottom: "1rem" }}
              />
            </Grid>

          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <p>Features:</p>
              {Features?.map((item) => (
                <FormControlLabel
                  key={item.id}
                  control={
                    <Checkbox
                      checked={formValues?.features?.includes(`${item.id}`)}
                      onChange={handleCheckboxChange}
                      name={item.id}
                    />
                  }
                  label={item.name}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="rent"
                label="Rent"
                type="number"
                variant="outlined"
                value={formValues.rent}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="facebook"
                label="Facebook URL"
                variant="outlined"
                value={formValues.facebook}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="instagram"
                label="Instagram URL"
                variant="outlined"
                value={formValues.instagram}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="whatsapp"
                label="Whatsapp"
                variant="outlined"
                type="phone"

                value={formValues.whatsapp}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="video_link"
                label="Video URL"
                variant="outlined"
                value={formValues.video_link}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="contact_number"
                label="Contact Number"
                variant="outlined"
                value={formValues.contact_number}
                onChange={handleFormChange}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Location</InputLabel>
                <Select
                  name="locations"
                  value={formValues.city}
                  onChange={settingLocationByDropDown}
                  defaultValue={""}
                  label="locations"
                >
                  <MenuItem value="" disabled>
                    Select Location
                  </MenuItem>
                  {/* Render dropdown options dynamically */}
                  {locations?.map((category) => (
                    <MenuItem key={category.id} value={category.city}>
                      {category.city}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <h4>Please Select Your Location:</h4>
            {console.log("first", formValues)}
            {/* <MapWithPinPoint
              choseLocation={settingLocation}
              pos={
                formValues.lat
                  ? [formValues.lat, formValues.lon]
                  : [33.7077, 73.0498]
              }
            /> */}
            <GoogleMapComponent lat={formValues.lat ? formValues.lat : 33.7077} lon={formValues.lon ? formValues.lon : 73.0498} />
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2}>
          <Grid item xs={12}>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="thumb-upload"
            type="file"
            
            onChange={handleThumbChange}
          />
          <label htmlFor="thumb-upload">
            <Button
              variant="contained"
              component="span"
              startIcon={<PhotoCamera />}
            >
              Upload Thumb
            </Button>
          </label>
          <Box mt={12} style={{ display: "flex", flexWrap: "wrap" }}>
          {formValues?.thumb?.map((image, index) => (
            <div
                key={index} // Moved the key to the outer div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center", // Center align content horizontally
                  width: "200px",
                  margin: "10px",
                }}
              >
                <img
                  src={isEditing ? image.image : URL.createObjectURL(image)}
                  alt="Listing Images"
                  width="200"
                  height="200"
                  style={{
                    margin: "5px",
                    border: "1px solid",
                    borderRadius: "2px",
                  }}
                />
                <Button
                  variant="outlined"
                  color="secondary"
                  component="span"
                  startIcon={<DeleteIcon />}
                  style={{ marginTop: "5px" }} // Add margin to separate image and button
                  onClick={() => handleRemoveImage(image)}
                >
                  Remove Image
                </Button>
              </div>
            ))}
          </Box>
        </Grid>
            <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="image-upload"
                type="file"
                multiple
                onChange={handleImageChange}
              />
              <label htmlFor="image-upload">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<PhotoCamera />}
                >
                  Upload Images
                </Button>
              </label>
              <Box mt={12} style={{ display: "flex", flexWrap: "wrap" }}>
                {formValues?.gallery?.map((image, index) => (
                  <div
                    key={index} // Moved the key to the outer div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center", // Center align content horizontally
                      width: "200px",
                      margin: "10px",
                    }}
                  >
                    <img
                      src={isEditing ? image.image : URL.createObjectURL(image)}
                      alt="Listing Images"
                      width="200"
                      height="200"
                      style={{
                        margin: "5px",
                        border: "1px solid",
                        borderRadius: "2px",
                      }}
                    />
                    <Button
                      variant="outlined"
                      color="secondary"
                      component="span"
                      startIcon={<DeleteIcon />}
                      style={{ marginTop: "5px" }} // Add margin to separate image and button
                      onClick={() => handleRemoveImage(image)}
                    >
                      Remove Image
                    </Button>
                  </div>
                ))}
              </Box>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="lg">
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.container}>
        <div className="justify-content-md-end flex-column  flex-md-row">
          <Typography variant="h4" align="center" gutterBottom>
            Add Paid Listing
          </Typography>
          <div
            style={{
              display: "flex",

              justifyContent: "end",
            }}
            className="justify-content-end  flex  flex-row align-items-end "
          >
            <div style={{ marginLeft: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleButtonClick}
              >
                Add Feature
              </Button>
              <AddFeatureForm
                isOpen={isModalOpen}
                newFeatures={setFeatures}
                onClose={handleCloseModal}
              />
            </div>
            <div style={{ marginLeft: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCatButtonClick}
              >
                Add Category
              </Button>
              <AddCategoryFormModal
                isOpen={isCatModalOpen}
                onClose={handleCatCloseModal}
                newCategories={setCategories}
              />
            </div>
          </div>
        </div>
        <hr />

        <Stepper my={4} activeStep={activeStep}>
          <Step>
            <StepLabel>Step 1</StepLabel>
          </Step>
          <Step>
            <StepLabel>Step 2</StepLabel>
          </Step>
          <Step>
            <StepLabel>Step 3</StepLabel>
          </Step>
        </Stepper>
        <Box my={4} bgcolor="white" p={2}>
          {renderStepContent(activeStep)}
          <Box display="flex" justifyContent="space-between" mt={4}>
            {activeStep > 0 && (
              <Button variant="contained" onClick={handleBack}>
                Back
              </Button>
            )}
            {activeStep < 2 && (
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            )}
            {activeStep === 2 && (
              <Button variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Box>
        </Box>
      </div>
      <Typography variant="h4" align="center" gutterBottom>
        All Listing
      </Typography>
      {listings?.map((listing) => (
        <Box key={listing.id} bgcolor="white" my={2} p={2} border={1}>
          <Typography variant="h6">{listing.title}</Typography>
          <Typography variant="subtitle1">{listing?.location?.city}</Typography>
          {/* Render other listing details */}
          <Box mt={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEdit(listing)}
            >
              Edit
            </Button>
            <Button
              style={{ marginLeft: "10px" }}
              variant="outlined"
              color="secondary"
              onClick={() => handleDelete(listing.id)}
            >
              Delete
            </Button>
          </Box>
        </Box>
      ))}
    </Container>
  );
};

export default ListingForm;
