import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import toastr, { subscribe } from "toastr";
import api from "../../api/api";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

const Contacts = () => {
  const [subcribers, setsubcribers] = useState([
    // Add more feature objects as needed
  ]);

  const [refetch, setrefetch] = useState(false);

  const handleDelete = async (id) => {
    // Replace this with your API endpoint and logic for deleting data

    try {
      const response = await api.delete(`admin/contact-us/${id}`);

      console.log(response);
      toastr.success(response.data.message);
      setrefetch(!refetch);
    } catch (error) {
      // Handle error

      toastr.error("Error while deleting message");
    }
  };

  const handleStaus = async (id) => {
    // Replace this with your API endpoint and logic for deleting data

    try {
      const response = await api.put(`admin/contact-us/${id}`, { status: 1 });

      console.log(response);
      toastr.success(response.data.message);
      setrefetch(!refetch);
    } catch (error) {
      // Handle error

      toastr.error("Error while changing status");
    }
  };

  const classes = useStyles();
  const fetchData = async () => {
    try {
      const response = await api.get(`admin/contact-us`);

      // Process the response data
      const data = response.data;
      setsubcribers(data);
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  };

  const copyToClipBoard = (data, message) => {
    navigator.clipboard.writeText(data);
    toastr.success(`${message} copied to clipboard`);
  };

  useEffect(() => {
    fetchData();
  }, [refetch]);

  return (
    <div className="flex">
      <div className="w-1/2">
        <h2>Messages</h2>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subcribers.map((subcriber) => (
                <TableRow
                  key={subcriber.id}
                  style={{ verticalAlign: "baseline" }}
                >
                  <TableCell>{subcriber.id}</TableCell>
                  <TableCell>{subcriber?.name?.toLowerCase()}</TableCell>
                  <Tooltip title="Click to copy" placement="bottom-end">
                    <TableCell
                      onClick={() => copyToClipBoard(subcriber.email, "Email")}
                    >
                      {subcriber?.email?.toLowerCase()}
                    </TableCell>
                  </Tooltip>

                  <Tooltip title="Click to copy" placement="bottom-end">
                    <TableCell
                      onClick={() =>
                        subcriber?.status == 1
                          ? copyToClipBoard(subcriber.message, "Message")
                          : ""
                      }
                    >
                      {subcriber?.status == 0 ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() => handleStaus(subcriber?.id)}
                        >
                          View
                        </Button>
                      ) : (
                        subcriber?.message?.toLowerCase()
                      )}
                    </TableCell>
                  </Tooltip>
                  <TableCell>
                    {subcriber?.status == 0 ? "Unread" : "Read"}
                  </TableCell>
                  <TableCell>
                    {subcriber?.created_at?.toLowerCase().slice(0, 10)}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleDelete(subcriber?.id)}
                      //   onClick={() => handleDelete(subcriber.id, "feature")}
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Contacts;
