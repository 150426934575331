import React from "react";
import myImage from "../../assets/images/tuat.png";
import "./topnav.css";
const UserAvatar = ({ userName, onClick }) => {
  return (
    <div className="user-avatar" onClick={onClick}>
      <img src={myImage} alt="User Avatar" className="avatar-image" />
      <span className="user-name">{userName}</span>
    </div>
  );
};

export default UserAvatar;
