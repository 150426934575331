import React from "react";
import ListingForm from "./ListingForm";

function AddListing() {
  return (
    <div>
      <ListingForm />
      {/* <div>
        <h1>Location Search</h1>
        <LocationSearch />
      </div> */}
    </div>
  );
}

export default AddListing;
