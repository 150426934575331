import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TableBody,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import toastr from "toastr";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import api from "../../api/api";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

const Subscribers = () => {
  const [refetch, setrefetch] = useState(false);
  const [subcribers, setsubcribers] = useState([
    // Add more feature objects as needed
  ]);

  const handleDelete = async (id) => {
    // Replace this with your API endpoint and logic for deleting data

    try {
      const response = await api.delete(`admin/subscriber/${id}`);

      console.log(response);
      toastr.success(response.data.message);
      fetchData();
    } catch (error) {
      // Handle error

      toastr.error("Error while deleting message");
    }
  };

  const copyToClipBoard = (data) => {
    navigator.clipboard.writeText(data);
    toastr.success("Email copied to clipboard ");
  };
  const fetchData = async () => {
    try {
      const response = await api.get(`admin/subscribers`);

      // Process the response data
      const data = response.data;
      setsubcribers(data);
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetch]);

  return (
    <div className="flex">
      <div className="w-1/2">
        <h2>Subcribers</h2>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subcribers.map((subcriber) => (
                <TableRow key={subcriber.id}>
                  <TableCell>{subcriber.id}</TableCell>
                  <TableCell>{subcriber?.name?.toLowerCase()}</TableCell>
                  <Tooltip title="Click to copy" placement="bottom-end">
                    <TableCell onClick={() => copyToClipBoard(subcriber.email)}>
                      {subcriber?.email?.toLowerCase()}
                    </TableCell>
                  </Tooltip>
                  <TableCell>
                    <IconButton
                      onClick={() => handleDelete(subcriber.id)}
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Subscribers;
